input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
  @include font-size(2);
  position: relative;
  border: 0;
  border-bottom: 1px solid $color-border-input;
  border-radius: 0;
  background-color: transparent;
  color: $color-text-input;
  box-shadow: none;
  z-index: 1;

  &:focus {
    border-bottom: 1px #000 solid;
    color: $color-text-input-focus;
    box-shadow: none;
  }
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
  padding: 3px;
  padding-left: 10px;
}

textarea {
  width: 100%;
  padding-left: 3px;
}
