/*
* Usage: https://smacss.com/book/type-module
**/


%center-x,
.center-x {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

%center-y,
.center-y {
  position: absolute;
  top: 50%;
  // width: 100%;
  transform: translateY(-50%);
}

.hero {
  position: relative;
  padding: 200px 0;
  background: url("../img/barrels.jpg") center center no-repeat;
  background-size: cover;
  text-align: center;
  height: 60vh;

  @include respond-to(xs) {
    height: 100vh;
  }

  .center-y {
    width: 100%;
  }
}

.logo {
  width: 200px;

  @include respond-to(xs) {
    width: auto;
  }
}

.title {
  position: relative;
  padding: 50px 25px;
  background: url("../img/drinking-bg.jpg") center center no-repeat;
  background-size: cover;


  @include respond-to(xs) {
    padding: 100px 25px;
  }
}

.splash {
  width: 80px;
  margin: 0 auto;

  @include respond-to(xs) {
    width: auto;

    img {
      max-width: 50%;
    }
  }
}

.page-section {
  padding: 25px 0;

  @include respond-to(xs) {
    padding: 100px 0;
  }
}

.about-section {
 background-image: url('../img/who-we-are-bg.jpg');
 background-size: cover;
 background-position: right;

 @include respond-to(xs) {
   background-position: center;
 }

  &__copy {
    color: #fff;
    margin: 0 auto;

    @include respond-to(xs) {
      margin-left: auto;
      margin-right: 0;
    }

    h2 {
      color: #fff;
    }
  }
}

.divider {
  padding: 0;

  @include respond-to(xs) {
    padding: 0 26px;
  }
}

.portfolio-intro-section {
  &__text {
    padding: 0;

    @include respond-to(md) {
      padding: 0 190px;
    }
  }
}

.portfolio-section {
  position: relative;
  padding: 40px 0;

  @include respond-to(xs) {
    padding: 100px 0;
  }

  &__item {
    @include flexbox();


    @include respond-to(xs) {
      @include flex-flow(row wrap);
    }
  }

  &__item--col-reverse {
    // flex-direction: column-reverse;

    @include respond-to(xs) {
      // flex-direction: row;
    }

  }

  p {
    @include font-size(1.4);
    padding: auto;

    @include respond-to(xs) {
      @include font-size(1.6);
      padding: 0 15px
    }
  }

  img {
    width: auto;
    max-height: 200px;

    @include respond-to(xs) {
      max-height: 435px;
    }
  }

  h2 {
    padding-bottom: 0.5em;
    margin: 0 auto;

    @include respond-to(xs) {
      margin: 0 0;
    }

    img {
      max-width: 100px;
      padding-bottom: 0.5em;

      @include respond-to(xs) {
        max-width: 250px;
        padding: 0 15px
      }
    }
  }
}


.footer {
  padding: 25px 0;
  background: #616161;
  text-align: center;

  @include respond-to(xs) {
    padding: 75px 0;
  }

  &-logo {
    width: 350px;

    @include respond-to(xs) {
      width: 630px;
    }
  }

  a, p {
    @include font-size(2);
    color: #fff;
  }
}

.contact {
  @include font-size(2);
  margin-top: 25px;
  margin-bottom: 0;
  color: #fff;

  @include respond-to(xs) {
    margin-top: 75px;
  }

  &__heading {
    @include font-size(2.4);
  }

  a {
    text-decoration: none;
  }

  &__email {
    margin: 5px 0 -14px 0;
    display: block;
  }
}

.form-group {
  position: relative;
  margin-top: 40px;
}

.message {
  @include font-size(1.6);
  display: none;
  padding: 4px;
  color: #000;

  a {
    color: #000;
  }
}
