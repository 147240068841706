/*
Theme Name: blank
Text Domain: blank
Author: Pace Developers
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/
/*--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 Normalize
--------------------------------------------------------------*/
html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  margin: .67em 0;
  font-size: 2em; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  height: 0;
  box-sizing: content-box; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font: inherit; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  box-sizing: content-box;
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  margin: 0 2px;
  padding: .35em .625em .75em;
  border: 1px solid #c0c0c0; }

legend {
  padding: 0;
  border: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-base
**/
html {
  font-size: 10px;
  box-sizing: border-box; }

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit; }

body {
  background: #fff;
  /* Fallback for when there is no custom background color defined. */ }

body,
button,
input,
select,
textarea {
  font-size: 1rem;
  color: #565656;
  font-family: "mr-eaves-xl-modern", sans-serif;
  font-weight: normal;
  line-height: 1.5; }

b,
strong {
  font-weight: 600; }

blockquote,
q {
  quotes: "" ""; }
  blockquote:before, blockquote:after,
  q:before,
  q:after {
    content: ""; }

hr {
  height: 1px;
  border: 0;
  background-color: #beb7b3;
  margin: 0; }

label {
  font-size: 2rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: .4s all;
  font-weight: normal;
  z-index: 0; }
  :focus + label,
  :active + label,
  .active + label {
    font-size: 1.6rem;
    bottom: 25px;
    left: 0;
    transform: translateX(0); }

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%; }

ul,
ol {
  margin: 1.5em 0;
  padding-left: 1.5em; }
  @media (max-width: 768px) {
    ul,
    ol {
      font-size: 1.4rem; } }
  @media (min-width: 768px) {
    ul,
    ol {
      font-size: 1.4rem; } }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em; }

dt {
  font-weight: bold; }

dd {
  margin: 0 1.5em 1.5em; }

table {
  width: 100%;
  margin: 0 0 1.5em; }
  @media (max-width: 768px) {
    table {
      font-size: 1.4rem; } }
  @media (min-width: 768px) {
    table {
      font-size: 1.4rem; } }
  table th {
    padding: 10px 20px; }
    @media (max-width: 992px) {
      table th {
        padding: 5px; } }
  table td {
    padding: 2px 20px; }
    @media (max-width: 992px) {
      table td {
        padding: 2px 15px; } }

a {
  color: #333;
  text-decoration: underline; }
  a:visited {
    color: #333; }
  a:hover, a:focus, a:active {
    color: #000; }
  a:focus {
    outline: thin dotted; }
  a:hover, a:active {
    outline: 0; }

.btn {
  font-size: 2rem;
  padding: 3px 35px;
  transition: .2s background;
  border-radius: 0; }
  .btn:focus, .btn:active, .btn:hover, .btn.active, .btn.active:focus, .btn.active:active, .btn.active:hover {
    box-shadow: none; }

p {
  font-size: 1.6rem;
  margin: .5em 0;
  line-height: 1.13; }
  @media (min-width: 768px) {
    p {
      font-size: 1.8rem; } }

dfn,
cite,
em,
i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

pre {
  font-size: 0.9375rem;
  max-width: 100%;
  margin-bottom: 1.6em;
  padding: 1.6em;
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  line-height: 1.6;
  overflow: auto; }

code,
kbd,
tt,
var {
  font-size: 0.9375rem;
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace; }

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

mark,
ins {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5em;
  clear: both;
  font-family: "mr-eaves-xl-modern", sans-serif;
  font-weight: 300;
  line-height: 1.5; }

h1 {
  font-size: 4rem;
  margin: 0;
  color: #fff;
  line-height: 1; }
  @media (min-width: 768px) {
    h1 {
      font-size: 14.5rem; } }

h2 {
  font-size: 2.8rem;
  margin: 0;
  color: #000; }
  @media (min-width: 768px) {
    h2 {
      font-size: 6.7rem; } }
  @media (min-width: 992px) {
    h2 {
      font-size: 8.7rem; } }

button,
input[type="button"],
input[type="reset"] {
  padding: .6em 1em .4em;
  border: 1px solid;
  border-radius: 3px;
  border-color: #ccc #ccc #bbb;
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), inset 0 15px 17px rgba(255, 255, 255, 0.5), inset 0 -5px 12px rgba(0, 0, 0, 0.05); }
  @media (max-width: 768px) {
    button,
    input[type="button"],
    input[type="reset"] {
      font-size: 1.4rem; } }
  @media (min-width: 768px) {
    button,
    input[type="button"],
    input[type="reset"] {
      font-size: 1.4rem; } }
  button:hover,
  input[type="button"]:hover,
  input[type="reset"]:hover {
    border-color: #ccc #bbb #aaa;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8), inset 0 15px 17px rgba(255, 255, 255, 0.8), inset 0 -5px 12px rgba(0, 0, 0, 0.02); }
  button:active, button:focus,
  input[type="button"]:active,
  input[type="button"]:focus,
  input[type="reset"]:active,
  input[type="reset"]:focus {
    border-color: #aaa #bbb #bbb;
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5), inset 0 2px 5px rgba(0, 0, 0, 0.15); }

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
  font-size: 2rem;
  position: relative;
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  background-color: transparent;
  color: #fff;
  box-shadow: none;
  z-index: 1; }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  textarea:focus {
    border-bottom: 1px #000 solid;
    color: #fff;
    box-shadow: none; }

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
  padding: 3px;
  padding-left: 10px; }

textarea {
  width: 100%;
  padding-left: 3px; }

/*--------------------------------------------------------------
3.0 Accessibility
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-module
**/

.center-x {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%); }


.center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.hero {
  position: relative;
  padding: 200px 0;
  background: url("../img/barrels.jpg") center center no-repeat;
  background-size: cover;
  text-align: center;
  height: 60vh; }
  @media (min-width: 768px) {
    .hero {
      height: 100vh; } }
  .hero .center-y {
    width: 100%; }

.logo {
  width: 200px; }
  @media (min-width: 768px) {
    .logo {
      width: auto; } }

.title {
  position: relative;
  padding: 50px 25px;
  background: url("../img/drinking-bg.jpg") center center no-repeat;
  background-size: cover; }
  @media (min-width: 768px) {
    .title {
      padding: 100px 25px; } }

.splash {
  width: 80px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .splash {
      width: auto; }
      .splash img {
        max-width: 50%; } }

.page-section {
  padding: 25px 0; }
  @media (min-width: 768px) {
    .page-section {
      padding: 100px 0; } }

.about-section {
  background-image: url("../img/who-we-are-bg.jpg");
  background-size: cover;
  background-position: right; }
  @media (min-width: 768px) {
    .about-section {
      background-position: center; } }
  .about-section__copy {
    color: #fff;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .about-section__copy {
        margin-left: auto;
        margin-right: 0; } }
    .about-section__copy h2 {
      color: #fff; }

.divider {
  padding: 0; }
  @media (min-width: 768px) {
    .divider {
      padding: 0 26px; } }

.portfolio-intro-section__text {
  padding: 0; }
  @media (min-width: 1200px) {
    .portfolio-intro-section__text {
      padding: 0 190px; } }

.portfolio-section {
  position: relative;
  padding: 40px 0; }
  @media (min-width: 768px) {
    .portfolio-section {
      padding: 100px 0; } }
  .portfolio-section__item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
    @media (min-width: 768px) {
      .portfolio-section__item {
        -webkit-flex-flow: row wrap;
        -moz-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap; } }
  .portfolio-section p {
    font-size: 1.4rem;
    padding: auto; }
    @media (min-width: 768px) {
      .portfolio-section p {
        font-size: 1.6rem;
        padding: 0 15px; } }
  .portfolio-section img {
    width: auto;
    max-height: 200px; }
    @media (min-width: 768px) {
      .portfolio-section img {
        max-height: 435px; } }
  .portfolio-section h2 {
    padding-bottom: 0.5em;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .portfolio-section h2 {
        margin: 0 0; } }
    .portfolio-section h2 img {
      max-width: 100px;
      padding-bottom: 0.5em; }
      @media (min-width: 768px) {
        .portfolio-section h2 img {
          max-width: 250px;
          padding: 0 15px; } }

.footer {
  padding: 25px 0;
  background: #616161;
  text-align: center; }
  @media (min-width: 768px) {
    .footer {
      padding: 75px 0; } }
  .footer-logo {
    width: 350px; }
    @media (min-width: 768px) {
      .footer-logo {
        width: 630px; } }
  .footer a, .footer p {
    font-size: 2rem;
    color: #fff; }

.contact {
  font-size: 2rem;
  margin-top: 25px;
  margin-bottom: 0;
  color: #fff; }
  @media (min-width: 768px) {
    .contact {
      margin-top: 75px; } }
  .contact__heading {
    font-size: 2.4rem; }
  .contact a {
    text-decoration: none; }
  .contact__email {
    margin: 5px 0 -14px 0;
    display: block; }

.form-group {
  position: relative;
  margin-top: 40px; }

.message {
  font-size: 1.6rem;
  display: none;
  padding: 4px;
  color: #000; }
  .message a {
    color: #000; }

/*--------------------------------------------------------------
4.0 Layout
--------------------------------------------------------------*/
/*
* Usage: https://smacss.com/book/type-layout
**/
/*--------------------------------------------------------------
5.0 Misc
--------------------------------------------------------------*/
/*
* Usage: Utility classes that do not fit anywhere in particular into SMACSS (https://smacss.com/book/)
**/
/* Text meant only for screen readers. */
.screen-reader-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }
  .screen-reader-text:hover, .screen-reader-text:active, .screen-reader-text:focus {
    font-size: 0.875rem;
    display: block;
    top: 5px;
    left: 5px;
    width: auto;
    height: auto;
    padding: 15px 23px 14px;
    border-radius: 3px;
    background-color: #f1f1f1;
    color: #565656;
    font-weight: bold;
    line-height: normal;
    text-decoration: none;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    z-index: 100000;
    /* Above WP toolbar. */
    clip: auto !important; }

.clear:before,
.clear:after {
  content: "";
  display: table; }

.clear:after {
  clear: both; }

.flex {
  display: flex; }

.center-content {
  margin: 0 auto; }

.v-center-col {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }

.col-reverse {
  flex-direction: column-reverse; }

/*--------------------------------------------------------------
6.0 Shaaaaaaaaaaaaame
--------------------------------------------------------------*/
/*
* Usage: Anything you aren't proud of, or hope/plan to fix later. Hopefully empty, but useful for identifying pain points when it's not.
**/
/*--------------------------------------------------------------
7.0 Overriding Bootstrap Styles
--------------------------------------------------------------*/
.portfolio-section__item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }
  @media only screen and (max-width: 767px) {
    .portfolio-section__item--col-reverse > div:first-child {
      -webkit-box-ordinal-group: 2;
      -moz-box-ordinal-group: 2;
      -ms-flex-order: 2;
      -webkit-order: 2;
      order: 2; } }
  @media only screen and (max-width: 767px) {
    .portfolio-section__item h2 {
      text-align: center; } }
