p {
  @include font-size(1.6);
  margin: .5em 0;
  line-height: 1.13;

  @include respond-to(xs) {
    @include font-size(1.8);
  }

  // @include respond-to(sm) {
  //   @include font-size(3.3);
  // }
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  @include font-size(.9375);
  max-width: 100%;
  margin-bottom: 1.6em;
  padding: 1.6em;
  background: $color-background-pre;
  font-family: $font-pre;
  line-height: $font-line-height-pre;
  overflow: auto;
}

code,
kbd,
tt,
var {
  @include font-size(.9375);
  font-family: $font-code;
}

abbr,
acronym {
  border-bottom: 1px dotted $color-border-abbr;
  cursor: help;
}

mark,
ins {
  background: $color-background-ins;
  text-decoration: none;
}

big {
  font-size: 125%;
}
