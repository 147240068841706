h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5em;
  clear: both;
  font-family: $font-heading;
  font-weight: 300;
  line-height: 1.5;
}

h1 {
  @include font-size(4);
  margin: 0;
  color: #fff;
  line-height: 1;

  @include respond-to(xs) {
    @include font-size(14.5);
  }
}

h2 {
  @include font-size(2.8);
  margin: 0;
  color: #000;

  @include respond-to(xs) {
    @include font-size(6.7);
    // margin: 1.5em 0 0;
  }

  @include respond-to(sm) {
    @include font-size(8.7);
  }
}
