a {
  color: $color-link;
  // font-weight: bold;
  text-decoration: underline;

  &:visited {
    color: $color-link-visited;
  }

  &:hover,
  &:focus,
  &:active {
    color: $color-link-hover;
  }

  &:focus {
    outline: thin dotted;
  }

  &:hover,
  &:active {
    outline: 0;
  }
}

.btn {
  @include font-size(2);
  padding: 3px 35px;
  transition: .2s background;
  border-radius: 0;

  &:focus,
  &:active,
  &:hover,
  &.active,
  &.active:focus,
  &.active:active,
  &.active:hover {
    box-shadow: none;
  }
}
