$color-background-body: #fff;
$color-background-screen: #f1f1f1;
$color-background-hr: #beb7b3;
$color-background-button: #e6e6e6;
$color-background-pre: #eee;
$color-background-ins: #fff9c0;

$color-text-main: #565656;
$color-text-screen: $color-text-main;
$color-text-input: #fff;
$color-text-input-focus: #fff;
$color-link: #333;
$color-link-visited: #333;
$color-link-hover: #000;

$color-border-button: #ccc #ccc #bbb;
$color-border-button-hover: #ccc #bbb #aaa;
$color-border-button-focus: #aaa #bbb #bbb;
$color-border-input: #ccc;
$color-border-abbr: #666;
