/*
* Usage: https://smacss.com/book/type-base
**/
html {
  font-size: 10px;
  box-sizing: border-box;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit;
}

body {
  background: $color-background-body; /* Fallback for when there is no custom background color defined. */
}

body,
button,
input,
select,
textarea {
  @include font-size(1);
  color: $color-text-main;
  font-family: $font-main;
  font-weight: normal;
  line-height: $font-line-height-body;
}

b,
strong {
  font-weight: 600;
}

blockquote,
q {
  quotes: "" "";

  &:before,
  &:after {
    content: "";
  }
}

hr {
  height: 1px;
  border: 0;
  background-color: $color-background-hr;
  margin: 0;
}


label {
  @include font-size(2);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: .4s all;
  font-weight: normal;
  z-index: 0;

  :focus + &,
  :active + &,
  .active + & {
    @include font-size(1.6);
    bottom: 25px;
    left: 0;
    transform: translateX(0);
  }
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

@import "lists";
@import "tables";
@import "links";
@import "copy";
@import "headings";

@import "forms__buttons";
@import "forms__fields";
