.flex {
  display: flex;
}

.center-content {
  margin: 0 auto;
}

.v-center-col {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  @include flex-flow(row wrap);
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.col-reverse {
  flex-direction: column-reverse;
}
