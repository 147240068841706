.portfolio-section {

  &__item {
    @include flexbox();
    @include flex-flow(row wrap);

    &--col-reverse{
      >div{
        &:first-child{
          @media only screen and (max-width: 767px){
            @include order(2);
          }
        }
      }
    }

    h2{
      @media only screen and (max-width: 767px){
        text-align: center;
      }
    }
  }
}
